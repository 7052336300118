import {
	Component,
	ElementRef, inject,
	OnDestroy,
	OnInit,
	signal, TemplateRef, viewChild,
	ViewChild,
} from '@angular/core';
import { MessagingService } from './messaging.service';
import {
	ChatRoom,
	ChatUser,
	Message,
	MessageSource,
	MessageType,
} from './models';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserSelectComponent } from './user-select';
import { WsService } from '../ws.service';
import {DEFAULT_SEARCH_CONFIG, WsMessage} from '../model';
import { DeleteOptionComponent } from './delete-option.component';
import { ActivatedRoute } from '@angular/router';
import { deleteItemFromArray } from '../shared';
import { reverse as _reverse, sortBy } from 'lodash';
import { formatTimer } from '../utils';
import { saveAs } from 'file-saver';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SearchService} from "../shared/advanced-search/search.service";

@Component({
	selector: 'ft-messaging',
	templateUrl: './messaging.component.html',
	styleUrls: ['./messaging.component.scss'],
})
export class MessagingComponent implements OnInit, OnDestroy {
	public uploadProgress: number;
	public me: ChatUser = new ChatUser(
		null,
		JSON.parse(localStorage.getItem('user')).id,
		JSON.parse(localStorage.getItem('user')).fullName,
		true
	);
	public replyToMessage: Message = null;

	public currentContact: ChatUser;
	public filteredRooms: ChatRoom[] = [];

	@ViewChild('messageInput', { static: false }) messageInput: ElementRef;

	public selectedRoom: ChatRoom;
	public currentMessages: Message[] = [];
	private uploadSub: Subscription;
	private userSelectSub: Subscription;
	private chatRooms: ChatRoom[] = [];
	public roomFromMenu: ChatRoom;
	public selectionActivated: boolean;
	public selectedImage: Message;
	public typing = {};
	private sub$: Subscription;
	private searchSub$: Subscription;
	public searching: boolean;
	private roomFromUrl: number;
	public selectedMessages: Message[] = [];
	public recordingActivated: boolean;
	private hasMorePages: boolean = true;
	private page: number = 0;
	private pageSize: number = 16;
	private totalPages: number = 0;
	public emojis = Array(83)
		.fill(0)
		.map((e, i) => 'assets/emojis/' + i + '.png');
	public showEmojis: boolean;

	@ViewChild('message_box') messageBox: ElementRef;
	public isLoading: boolean;
	trackFn = (idx, item): any => item.id;
	filterForm: FormGroup;

	private _searchService = inject(SearchService);
	private _fb = inject(FormBuilder);

	searchTemplate = viewChild.required<TemplateRef<any>>('searchTemplate');

	constructor(
		private _service: MessagingService,
		private _dialog: MatDialog,
		private _ws: WsService,
		private _snackBar: MatSnackBar,
		private _route: ActivatedRoute
	) {
		this.filterForm = this._fb.group({key: ''});
		this.filterForm.get('key').valueChanges.subscribe(value => this.filterRooms(value));

		setTimeout(() => {
			this._searchService.searchInputConfig.set({
				placeholder: 'search.chat',
				expandable: false,
				hidden: false,
				template: this.searchTemplate()
			});

			this._searchService.genericSearchObs.subscribe(value => this.filterForm.get('key').patchValue(value));
		});

		this._service.getOrCreateChatUser(this.me).subscribe(user => {
			this.me = user;
			this.getRooms();
		});

		this._ws.chatUpdate.asObservable().subscribe((wsMessage: WsMessage) => {
			if (wsMessage) {
				switch (wsMessage.text) {
					case 'newMessage':
						this.receiveMessage(wsMessage.data as Message);
						break;
					case 'updateMessage':
						this.updateMessages(wsMessage.data);
						break;
					case 'status':
						this.updateContactStatus(wsMessage.data);
						break;
					case 'typing':
						this.updateTypingStatus(wsMessage.data);
						break;
					case 'played':
						this.onMessageSeen(wsMessage.data);
						break;
					case 'seen':
						this.onMessageSeen(wsMessage.data);
						break;
				}
			}
		});
	}

	private static scrollToBottom() {
		setTimeout(
			() => document.getElementById('bottom')?.scrollIntoView(),
			400
		);
	}

	ngOnInit(): void {
		this.sub$ = this._service
			.switchStatusTo(this.me.userId, 'online')
			.subscribe();
		this._route.queryParams.subscribe(
			params => (this.roomFromUrl = +params['roomId'])
		);
	}

	public getContact(room: ChatRoom): ChatUser {
		return room.user1.userId === this.me.userId ? room.user2 : room.user1;
	}

	public sendOnClick(html: any): void {
		if (html && html.trim()) {
			const text = MessagingComponent.htmlToText(html);
			const msg = this.buildMessage(text);
			this.saveMessage(msg);
			if (this.messageBox) this.messageBox.nativeElement.innerHTML = '';
		}
		MessagingComponent.scrollToBottom();
		this.sendTypingNotification(false);
	}

	newMessage() {
		this.userSelectSub = this._dialog
			.open(UserSelectComponent, {
				minWidth: '300px',
				data: { source: MessageSource.NEW, userId: this.me.userId },
			})
			.afterClosed()
			.subscribe(contacts => {
				if (contacts) {
					this._service
						.getOrCreateChatUser(contacts[0])
						.subscribe(user => {
							const room = this.findChatRoom(user);
							if (room) {
								this.selectedRoom = room;
								this.currentContact = this.getContact(room);
								this.getRoomMessages(room);
							} else {
								this.currentContact = user;
								this.saveChatRoom(
									new ChatRoom(
										0,
										this.me,
										this.currentContact
									)
								);
							}
						});
				}
			});
	}

	public openRoom(room: ChatRoom): void {
		this.selectedRoom = room;
		this.currentContact = this.getContact(this.selectedRoom);
		this.selectedRoom.unread = null;
		this.saveChatRoom(this.selectedRoom, true);
		this.getRoomMessages(this.selectedRoom);

		if (room.lastMessage?.seenDateTime === null)
			this.updateMessageSeenDate();
	}

	forwardMessage(message: Message) {
		this.userSelectSub = this._dialog
			.open(UserSelectComponent, {
				minWidth: '300px',
				data: { source: MessageSource.FORWARD, userId: this.me.userId },
			})
			.afterClosed()
			.subscribe(contacts => {
				if (contacts)
					contacts.forEach(contact =>
						this.forwardMessageToContact(message, contact)
					);
			});
	}

	deleteMessage(message: Message) {
		this._dialog
			.open(DeleteOptionComponent, {
				data: {
					canDeleteForEveryone:
						message.sender?.userId === this.me?.userId,
				},
			})
			.afterClosed()
			.subscribe(option =>
				this.deleteMessageAfterAction(message, option)
			);
	}

	selectMessage(message: Message, checked: boolean): void {
		if (checked) this.selectedMessages.push(message);
		else
			this.selectedMessages = deleteItemFromArray(
				this.selectedMessages,
				message
			);
	}

	public dateFormat(lastUpdate: any): string {
		return lastUpdate.getDate() === new Date().getDate()
			? 'HH:mm'
			: 'dd/MM/yyyy';
	}

	onFileSelected(event: any) {
		const file: File = event.target.files[0];

		let messageType = MessageType.IMAGE;

		if (file.type.includes('officedocument') || file.type.includes('pdf'))
			messageType = MessageType.FILE;

		if (file.type.includes('zip') || file.type.includes('rar')) {
			this._snackBar.open('Not supported yet', '', { duration: 2000 });
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = _event => {
			const msg = new Message();
			msg.messageType = messageType;
			msg.data = reader.result;
			msg.sender = this.me;
			msg.receiver = this.currentContact;
			msg.roomId = this.selectedRoom.id;
			msg.text = file.name;

			this.saveMessage(msg);
			MessagingComponent.scrollToBottom();
		};
	}

	cancelUpload() {
		this.uploadSub.unsubscribe();
		this.reset();
	}

	reset() {
		this.uploadProgress = null;
		this.uploadSub = null;
	}

	filterRooms(value?: any) {
		if (!value)
			this.filteredRooms = _reverse(
				sortBy(this.chatRooms, 'lastMessage.sentDateTime')
			);
		else
			this.filteredRooms = _reverse(
				sortBy(
					this.chatRooms.filter(it =>
						[it.user1.name, it.user2.name]
							.join(',')
							.toLowerCase()
							.includes(value.toLowerCase())
					),
					'lastMessage.sentDateTime'
				)
			);
	}

	filterMessages(event?: any) {
		const key = event?.target?.value;
		this.resetPagingAndData();
		this._service
			.getRoomMessages(
				this.selectedRoom.id,
				this.pageSize,
				this.page,
				key
			)
			.subscribe(data => {
				this.hasMorePages = !data['last'];
				this.currentMessages = sortBy(data['content'], 'sentDateTime');
				this.totalPages = +data['totalPages'];
				MessagingComponent.scrollToBottom();
			});
	}

	deleteRoom(room: ChatRoom) {
		room.deletedFor = this.me.id;
		this.saveChatRoom(room, true);
		if (room.id === this.selectedRoom?.id) this.selectedRoom = null;
		this.chatRooms.splice(
			this.chatRooms.findIndex(it => it.id === room.id),
			1
		);
		this.filteredRooms = _reverse(
			sortBy(this.chatRooms, 'lastMessage.sentDateTime')
		);
	}

	audioMessagePlayed(message: Message) {
		if (message.sender.id !== this.me.id) {
			message.seenDateTime = new Date();
			this._service.messagePlayed(message).subscribe();
		}
	}

	private saveMessage(msg: Message) {
		this._service.sendMessage(msg).subscribe(value => {
			if (value) {
				this.currentMessages = [...this.currentMessages, value];
				this.selectedRoom = this.findChatRoom(value.receiver);
				this.selectedRoom.lastMessage = value;
				this.saveChatRoom(this.selectedRoom, true);
			}
		});
	}

	openImage(message: Message) {
		this.selectedImage = message;
	}

	private findChatRoom(contact: ChatUser): ChatRoom {
		return this.chatRooms.find(cr =>
			[cr.user1.userId, cr.user2.userId].includes(contact.userId)
		);
	}

	private buildMessage(messageText: string): Message {
		const msg = new Message();
		msg.text = messageText;
		msg.sender = this.me;
		msg.receiver = this.currentContact;
		msg.roomId = this.selectedRoom.id;

		if (this.replyToMessage) {
			msg.replyTo = this.replyToMessage;
			this.replyToMessage = null;
		}

		return msg;
	}

	private openChatRoom(contact: ChatUser) {
		this.selectedRoom = this.findChatRoom(contact);
		this.currentContact = contact;
		this.getRoomMessages(this.selectedRoom);
	}

	getIcon(messageType: MessageType): string {
		switch (messageType) {
			case MessageType.IMAGE:
				return 'mdi-image';
			case MessageType.AUDIO:
				return 'mdi-microphone';
			default:
				return 'mdi-file';
		}
	}

	private forwardMessageCopy(msg: Message) {
		this._service.sendMessage(msg).subscribe(value => {
			if (value) {
				this.selectedRoom.lastMessage = value;
				this.saveChatRoom(this.selectedRoom, true);
			}
		});
	}

	deleteSelection() {
		this._dialog
			.open(DeleteOptionComponent, {
				data: {
					canDeleteForEveryone: !this.selectedMessages.find(
						m => m.sender.id !== this.me.id
					),
				},
			})
			.afterClosed()
			.subscribe(option =>
				this.selectedMessages.forEach(it =>
					this.deleteMessageAfterAction(it, option)
				)
			);
		this.selectionActivated = false;
	}

	private updateMessageSeenDate() {
		this._service
			.updateMessagesSeenDate(
				this.selectedRoom.id,
				this.me.userId,
				this.currentContact.userId
			)
			.subscribe(res => {
				this.getRoomMessages(this.selectedRoom);
				MessagingComponent.scrollToBottom();
			});
	}

	onRecordFinish(record: any) {
		const reader = new FileReader();
		reader.readAsDataURL(
			new File([record['blob']], 'audio.ogg', { type: 'audio/ogg' })
		);

		reader.onload = _ => {
			const msg = new Message();
			msg.messageType = MessageType.AUDIO;
			msg.data = reader.result;
			msg.sender = this.me;
			msg.duration = record['duration'];
			msg.receiver = this.currentContact;
			msg.roomId = this.selectedRoom.id;

			this.saveMessage(msg);
			MessagingComponent.scrollToBottom();
		};
	}

	private updateContactStatus(data: string) {
		const [userId, status] = data.split('@');
		if (this.currentContact?.userId === Number(userId))
			this.currentContact.online = status !== 'offline';
	}

	getAudioDuration(duration: number): string {
		return formatTimer(duration).substr(0, 5);
	}

	ngOnDestroy() {
		this.uploadSub?.unsubscribe();
		this.userSelectSub?.unsubscribe();
		this.reset();
		this._service.switchStatusTo(this.me.userId, 'offline').subscribe();
		if (this.sub$) this.sub$.unsubscribe();
		if (this.searchSub$) this.searchSub$.unsubscribe();

		this._searchService.searchInputConfig.set(DEFAULT_SEARCH_CONFIG);
	}

	private sendTypingNotification(typing: boolean): void {
		this._service
			.sendTypingNotification(
				this.me.userId,
				this.currentContact.userId,
				typing
			)
			.subscribe();
	}

	private updateTypingStatus(data: string) {
		const [id, status] = data.split('@');
		const [senderId, typing] = [Number(id), status === 'true'];
		this.typing[senderId] = typing;
	}

	private playSound() {
		this._ws.soundPlayer.next(true);
	}

	messageIsMine(message: Message): boolean {
		return message.sender && this.me.id === message.sender.id;
	}

	forwardSelection() {
		this.userSelectSub = this._dialog
			.open(UserSelectComponent, {
				minWidth: '300px',
				data: { source: MessageSource.FORWARD, userId: this.me.userId },
			})
			.afterClosed()
			.subscribe(contacts => {
				if (contacts)
					contacts.forEach(contact =>
						this.selectedMessages.forEach(message =>
							this.forwardMessageToContact(message, contact)
						)
					);
			});
		this.selectionActivated = false;
	}

	private forwardMessageToContact(message: Message, contact: ChatUser) {
		this._service.getOrCreateChatUser(contact).subscribe(user => {
			this.currentContact = user;

			const msg = Message.copyData(message);
			msg.sender = this.me;
			msg.receiver = user;

			const room = this.findChatRoom(user);
			if (room) {
				this.selectedRoom = room;
				msg.roomId = room.id;

				this.forwardMessageCopy(msg);
			} else
				this._service
					.createChatRoom(new ChatRoom(0, this.me, user))
					.subscribe(newRoom => {
						this.chatRooms.push(newRoom);
						msg.roomId = newRoom.id;
						this.selectedRoom = newRoom;

						this.forwardMessageCopy(msg);
					});
		});
	}

	private getRoomMessages(room: ChatRoom) {
		this.resetPagingAndData();

		this._service
			.getRoomMessages(room.id, this.pageSize, this.page++)
			.subscribe(data => {
				this.hasMorePages = !data['last'];
				this.currentMessages = sortBy(data['content'], 'sentDateTime');
				this.totalPages = +data['totalPages'];
				MessagingComponent.scrollToBottom();
				this.observeScrolling();
			});
	}

	private updateMessages(senderId: number) {
		const room = this.chatRooms.find(it =>
			[it.user1.userId, it.user2.userId].includes(senderId)
		);
		if (room) {
			room.lastMessage.seenDateTime = new Date();
			if (this.selectedRoom && room.id === this.selectedRoom.id)
				this.getRoomMessages(room);
		}
	}

	saveImage(selectedImage: Message) {
		saveAs(selectedImage.data, selectedImage.text);
	}

	private deleteMessageAfterAction(message: Message, option: any) {
		if (!option) return;
		message.deleted = true;
		message.deletedFor = option === 'ME' ? this.me.userId : option;
		message.replyTo = null;
		message.data = null;
		this._service.sendMessage(message).subscribe(value => {
			if (value) {
				this.getRoomMessages(this.selectedRoom);
				if (message.id === this.selectedRoom.lastMessage?.id) {
					this.selectedRoom.lastMessage = value;
					this.saveChatRoom(this.selectedRoom, true);
				}
			}
		});
	}

	private observeScrolling() {
		document
			.getElementById('message-feed')
			?.addEventListener('scroll', event => {
				const { scrollTop } = event.target as any;
				if (scrollTop === 0 && this.hasMorePages) {
					this.isLoading = true;
					if (this.page < this.totalPages)
						this._service
							.getRoomMessages(
								this.selectedRoom.id,
								this.pageSize,
								this.page++
							)
							.subscribe(data => {
								this.hasMorePages = !data['last'];
								this.currentMessages = [
									...sortBy(data['content'], 'sentDateTime'),
									...this.currentMessages,
								];
								this.isLoading = false;
							});
				}
			});
	}

	private resetPagingAndData() {
		this.page = 0;
		this.totalPages = 0;
		this.hasMorePages = true;
	}

	private getRooms(): void {
		this._service.getChatRooms(this.me.id).subscribe(rooms => {
			this.chatRooms = rooms;
			this.filteredRooms = _reverse(
				sortBy(rooms, 'lastMessage.sentDateTime')
			);

			if (!isNaN(this.roomFromUrl)) {
				const room = this.chatRooms.find(
					it => it.id === this.roomFromUrl
				);
				this.selectedRoom = room;
				this.currentContact =
					this.me.id === room.user1.id ? room.user2 : room.user1;
				this.getRoomMessages(this.selectedRoom);
			}
		});
	}

	private saveChatRoom(room, update: boolean = false) {
		this._service.createChatRoom(room).subscribe(cr => {
			if (cr && !update) {
				this.chatRooms.push(cr);
				this.openChatRoom(this.getContact(cr));
			}

			this.filteredRooms = _reverse(
				sortBy(this.chatRooms, 'lastMessage.sentDateTime')
			);
		});
	}

	private receiveMessage(message: Message) {
		if (message.deleted) {
			this.currentMessages = this.currentMessages.map(it => {
				if (message.id === it.id) {
					it.deleted = true;
					it.deletedFor = message.deletedFor;
				}
				return it;
			});

			this.filteredRooms = _reverse(
				sortBy(
					this.chatRooms.map(it => {
						if (it.lastMessage?.id === message.id)
							it.lastMessage = message;
						return it;
					}),
					'lastMessage.sentDateTime'
				)
			);
			return;
		} else if (this.selectedRoom) {
			if (message.messageType !== MessageType.AUDIO)
				message.seenDateTime = new Date();
			this.currentMessages = [...this.currentMessages, message];
			this.selectedRoom.lastMessage = message;

			this.saveChatRoom(this.selectedRoom, true);

			if (message.messageType !== MessageType.AUDIO)
				this._service.messageSeen(message).subscribe();

			this.playSound();
			MessagingComponent.scrollToBottom();
		} else {
			this._service.findRoom(message.roomId).subscribe(chatRoom => {
				const room = this.chatRooms.find(it => it.id === chatRoom.id);
				if (room) chatRoom = room;

				chatRoom.lastMessage = message;
				chatRoom.deletedFor = null;

				if (this.selectedRoom?.id === chatRoom.id) {
					this.currentMessages = [...this.currentMessages, message];
					this.updateMessageSeenDate();
					MessagingComponent.scrollToBottom();
				} else chatRoom.unread++;

				this.saveChatRoom(chatRoom, true);

				if (!room) this.getRooms();

				this.playSound();
			});
		}
	}

	private onMessageSeen(msg: Message) {
		this.currentMessages.forEach(it => {
			if (it.id === msg.id) it.seenDateTime = new Date();
		});
	}

	private get message_input() {
		return this.messageBox.nativeElement;
	}

	private static htmlToText(html: any): string {
		html = html.replaceAll('<img src="assets/emojis/', '[');
		html = html.replaceAll('.png" height="22">', ']');
		html = html.replaceAll('&nbsp;', ' ');
		html = html.replaceAll('<div>', '');
		html = html.replaceAll('<br>', '');
		html = html.replaceAll('</div>', '');
		return html;
	}

	selectEmoji(emoji: string) {
		const img = document.createElement('img');
		img.src = emoji;
		img.height = 22;
		this.message_input.append(img);

		this.message_input.focus();
		document.execCommand('selectAll', false, null);
		document.getSelection().collapseToEnd();
	}

	sendInput(inp: any) {
		if (inp && inp.keyCode === 13) {
			const html = inp.target.innerHTML;

			if (html && html.trim()) {
				const text = MessagingComponent.htmlToText(html);

				const msg = this.buildMessage(text);

				this.saveMessage(msg);

				this.message_input.innerHTML = '';
			}

			MessagingComponent.scrollToBottom();
			this.sendTypingNotification(false);
		}

		if (this.message_input.innerHTML === '')
			this.sendTypingNotification(false);
		else this.sendTypingNotification(true);
	}

	downloadFile(message: any) {
		saveAs(message.data, message.text);
	}
}
